/* eslint-disable object-curly-newline */
import { createMuiTheme } from '@material-ui/core/styles';
import {
    BACKGROUND_COLOR,
    BLACK,
    BLACK50,
    BLUE100,
    BLUE90,
    ERROR_COLOR,
    GRAY_SECONDARY,
    PRIMARY,
    SECONDARY,
    SECONDARY_BLUE,
    SECONDARY_GREEN,
    SECONDARY_ORANGE,
    SECONDARY_PURPLE,
    SECONDARY_RED,
    SUCCESS_COLOR,
    WARNING_COLOR,
} from '@theme_color';
import { FONT_24, FONT_DEFAULT, FONT_REGULAR } from '@theme_typography';
import { BREAKPOINTS } from '@theme_vars';

// Create a theme instance.
const theme = createMuiTheme({
    palette: {
        primary: {
            main: PRIMARY,
        },
        secondary: {
            main: SECONDARY,
        },
        secondaryblue: {
            main: SECONDARY_BLUE,
        },
        secondarygreen: {
            main: SECONDARY_GREEN,
        },
        secondaryorange: {
            main: SECONDARY_ORANGE,
        },
        secondarypurple: {
            main: SECONDARY_PURPLE,
        },
        blue100: {
            main: BLUE100,
        },
        blue90: {
            main: BLUE90,
        },
        secondaryred: {
            main: SECONDARY_RED,
        },
        error: {
            main: ERROR_COLOR,
        },
        success: {
            main: SUCCESS_COLOR,
        },
        warning: {
            main: WARNING_COLOR,
        },
        background: {
            default: BACKGROUND_COLOR,
        },
    },
    breakpoints: {
        values: {
            ...BREAKPOINTS,
        },
    },
    overrides: {
        MuiGrid: {
            root: {
                padding: 0,
                margin: 0,
            },
        },
        MuiTextField: {
            root: {
                ...FONT_REGULAR,
            },
        },
        MuiTypography: {
            h1: {
                ...FONT_24,
                ...FONT_DEFAULT,
            },
            root: {
                ...FONT_DEFAULT,
            },
        },
        MuiRadio: {
            root: {
                color: GRAY_SECONDARY,
                '&$checked': {
                    color: BLACK,
                },
            },
        },
        MuiFormControlLabel: {
            label: {
                ...FONT_REGULAR,
            },
            root: {
                marginBottom: -15,
            },
        },
        MuiDrawer: {
            paperAnchorRight: {
                background: 'transparent',
                boxShadow: 'none',
            },
        },
        MuiIcon: {
            root: {
                color: PRIMARY,
            },
        },
        MuiButton: {
            root: {
                borderRadius: 4,
                fontFamily: 'Montserrat !important',
                fontSize: '0.75rem',
            },
        },
        MuiBottomNavigationAction: {
            label: {
                fontFamily: 'Montserrat !important',
                color: BLACK50,
                fontSize: '10px',
                lineHeight: '18px',
                fontWeight: '600',
                '&$selected': {
                    fontSize: '12px',
                },
                '&.MuiBottomNavigationAction-iconOnly': {
                    opacity: 1,
                },
            },
        },
        MuiAutocomplete: {
            input: {
                padding: '0px !important',
            },
        },
        MuiInputBase: {
            input: {
                fontSize: '.75rem',
                fontFamily: 'Montserrat, sans-serif',
            },
        },
    },
});

export default theme;
