import { features } from '@config';
import _app from '@core_modules/theme/pages/_app';

/**
 * Import global css
 * */
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import '../src/styles/flexboxgrid.min.css';
import '../src/styles/fonts.css';
import '../src/styles/index.css';
import '../src/styles/mediaquery.css';
import '../src/styles/stylePhone.css';

if (features.useCustomStyle) {
    // eslint-disable-next-line global-require
    require('../src/styles/custom.css');
}

export default _app;
