export const MAX_WIDTH = '500px';
export const BREAKPOINTS = {
    xs: 0,
    xsm: 360,
    xmed: 400,
    xm: 480,
    sm: 768,
    md: 1024,
    lg: 1200,
    xl: 1920,
};
export default {};
